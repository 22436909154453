import React, { Fragment, useEffect } from "react";
import style from './index.module.less'
import classNames from 'classnames'
import useNavigate from '@/hooks/useNavigate'
import { useIntl } from "react-intl"
import { XButton } from '@/components/index'
// import SaleLogo from '@/images/icon/saleLogo.svg'
import {SaleLogo} from '@/components/index'
import { buy_link } from '@/scripts/constant.js'
import getCountry from '@/utils/getCountry.js'
import useGa from '@/hooks/useGa'
import { useInView } from "react-intersection-observer"

const Spec = ({ list, cate }) => {
    const region = getCountry()
    const [btnRef , btnRefInview] = useInView({ threshold: 0 });
    const intl = useIntl()
    const addSensor = (id, name) => {
        typeof sensors !== "undefined" &&  sensors.track('module_interaction', {
            custom: {
                module_id: id,
                products_name: name
            }
        });
    }

    useEffect(()=>{
        if(!!btnRefInview){
            typeof sensors !== "undefined" &&   sensors.track('module_view', {
                custom: {
                    module_id: 10011
                }
            });
        }
    },[btnRefInview])
    return (
        <div className={style.spec} >
            <h2 className={style.specTitle} id='air2spec'>{intl.formatMessage({ id: "specs_text" })}</h2>
            <div className={style.specList} >
                {list.map((item, index) => (
                    <Fragment key={index}>
                        <div className={style.specListColHeader} >
                            <img className={style.specListColHeaderImg} src={item.header.img} />
                            <div className={style.specListColHeaderColors} >
                                {item?.header?.colors.map((color) => (
                                    <div className={style.specListColHeaderColorsItem} style={{ backgroundColor: color }} key={color}></div>
                                ))}
                            </div>
                            <div className={style.specListColHeaderName} >{item.header.name}</div>
                            <div className={style.specListColHeaderDesc} >{item.header.desc}</div>
                            <div className={style.specListColHeaderPrice}><span style={{ textDecoration: !!item.header.shop_sale ? 'line-through' : "none" }}>{item.header.price}</span>
                                {item.header.shop_sale && <span className={style.specListColHeaderPriceSale}>{item.header.shop_sale}</span>}
                            </div>

                            <XButton

                                styles={{ width: '140px', marginTop: "20px" }} onClick={() => {
                                    addSensor(10010, item?.header?.pro_name);
                                    useGa(cate, `${item?.header?.router}_spec_learn_more`, 'Click'),
                                    useNavigate(`/${item.header.router}`)
                                }}>
                                <div className={style.specListColHeaderBtn_white} >
                                    {intl.formatMessage({ id: "specs_learn_more", defaultMessage: "Learn More" })}
                                </div>
                            </XButton> {!!item.header.shop && <XButton
                                type='secondary'
                                styles={{ width: '140px', marginTop: "20px" }} onClick={() => {
                                    addSensor(10011, item?.header?.pro_name);
                                    useGa(cate, `${item?.header?.router}_spec_buy`, 'Click'),
                                        window.open(item.header.shop)
                                }}>
                                {item.header.shop_sale && <SaleLogo text={buy_link[region]['air'].sale_shop_reduce}/>}

                                <div className={style.specListColHeaderBtn_black} ref={btnRef}>
                                    {intl.formatMessage({ id: "specs_buy_now", defaultMessage: "Buy Now" })}
                                </div>
                            </XButton>}

                        </div>
                        {item?.list?.map((ite, idx) => (
                            <div className={style.specListColItem} key={idx}>
                                {ite.text == '-' ?
                                    <div className={classNames(style.specListColItemLabel, style.line)}>{'-'}</div> : <Fragment>
                                        <div className={style.specListColItemLabel}>{ite?.label}</div>
                                        {!!ite.text && <div className={style.specListColItemText} dangerouslySetInnerHTML={{ __html: ite.text }}></div>}
                                    </Fragment>}
                            </div>
                        ))}
                    </Fragment>
                ))}
            </div>
        </div>
    )
}

export default Spec