import React, { useState, useEffect } from "react";
import style from './index.module.less'
import useBigScreen from "@/hooks/bigScreen"

const ProcessNav = ({ list, processShowId }) => {
    const [showIndex, setShowIndex] = useState(0)
    const [ifHover, setIfHover] = useState(false)
    const ifPc = useBigScreen(768)

    useEffect(() => {
        setShowIndex(processShowId)
    }, [processShowId])
    const processChange = (target, targetId) => {
        let opcation = document.querySelector(`.${target}`);
     
        if (!!opcation) {
            opcation.scrollIntoView({ behavior: 'smooth', block: "start" })
            setShowIndex(targetId)
        }

    }
    return (
        <div className={style.nav}>
            {list.map((item) => (
                <div className={style.navItem} key={`processNav${item.id}`}
                    onClick={() => processChange(item.classname, item.id)}
                    onMouseEnter={() => { setIfHover(true) }}
                    onMouseLeave={() => { setIfHover(false) }}
                    style={{overflow:(item.id == showIndex) && !ifHover ? 'visible' : 'hidden'}}
                >
                    <span style={{
                        color: item.id > showIndex ? '#666' : "#ccc",
                        opacity: (item.id == showIndex) && !ifHover ? 1 : 0,
                        fontSize:  ifPc ? '16px' : "12px"
                    }}>{item.title}</span>
                    <div className={style.dot} style={{
                        background: item.id > showIndex ? '#666' : "#ccc",
                        height: (item.id == showIndex) && !ifHover ? (ifPc?'24px':'20px') : '6px'
                    }}></div>
                </div>
            ))}
        </div>
    )
}

export default ProcessNav