import React ,{useEffect} from 'react'
import style from './index.module.less'
import {
    air2ProBuy,
    air2Buy,
    air2Buy_mobile,
    air2ProBuy_mobile
} from '@/images/air2/index'
import { XButton } from '@/components/index'
import { useIntl } from "react-intl"
import { buy_link } from '@/scripts/constant'
import useGa from '@/hooks/useGa'
import getCountry from '@/utils/getCountry.js'
import useBigScreen from "@/hooks/bigScreen"
import { useInView } from "react-intersection-observer"

const Air2Buy = () => {
    const intl = useIntl()
    const countryCode = getCountry()
    const ifPc = useBigScreen(768)
    const [btnRef , btnRefInview] = useInView({ threshold: 0 });
    useEffect(()=>{
        if(!!btnRefInview){
            typeof sensors !== "undefined" &&  sensors.track('module_view', {
                custom: {
                    module_id: 10012,
                    products_name: 'air2'
                }
            });
        }
    },[btnRefInview])
    const buyIntro = [
        {
            img: ifPc ? air2Buy : air2Buy_mobile,
            name: "XREAL Air 2",
            shopLink: buy_link[countryCode]['air2'].shop,
            amazonLink: buy_link[countryCode]['air2'].amazon,
            pro: "air2"
        },
        {
            img: ifPc ? air2ProBuy : air2ProBuy_mobile,
            name: "XREAL Air 2 Pro",
            shopLink: buy_link[countryCode]['air2pro'].shop,
            amazonLink: buy_link[countryCode]['air2pro'].amazon,
            pro: "air2pro"

        }
    ]

    const goBuyPage = (link, type, pro) => {
    
        useGa("Air2", `${pro}_below_buy${type == 'amazon' ? '_amazon' : ''}`, "Click")

        typeof sensors !== "undefined" &&  sensors.track('module_interaction', {
            custom: {
                module_id: type == 'shop' ? 10012 : 10013,
                products_name: pro
            }
        });

        window.open(link)

    }
    return (
        <div className={style.buy} id="pro-buy">
            {buyIntro.map((item, index) => (
                <div className={style.buyItem} key={`air2BuyBox${index}`}>
                    <img src={item.img} />
                    <div className={style.buyItemName}>{item.name}</div>
                    <div className={style.buyItemBtns}>
                        {
                            !!item.shopLink &&
                            <XButton width="200px" styles={{ margin: '10px' }}
                                onClick={() => { goBuyPage(item.shopLink, 'shop', item.pro) }}
                            ><div ref={btnRef}>{intl.formatMessage({ id: "buy_now", defaultMessage: "Buy Now" })}</div></XButton>
                        }
                        {
                            !!item.amazonLink &&
                            <XButton width="200px" styles={{ margin: '10px' }} onClick={() => { goBuyPage(item.amazonLink, 'amazon', item.pro) }}
                                type='secondary'
                            >{intl.formatMessage({ id: "buy_amazon", defaultMessage: "Buy on Amazon" })}</XButton>
                        }
                    </div>
                </div>
            ))}
        </div>
    )
}

export default Air2Buy